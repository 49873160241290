import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Settings } from 'luxon';
import { initReactI18next } from 'react-i18next';
import default_en from './default_en.json';
import default_fi from './default_fi.json';
import {
  FALLBACK_LANGUAGE,
  SUPPORTED_LANGUAGES,
  SupportedLanguages,
} from './i18n.interfaces';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    defaultNS: 'default',
    resources: {
      en: {
        default: default_en,
      },
      fi: {
        default: default_fi,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupCookie: 'valvuriLng',
      lookupLocalStorage: 'valvuriLng',
      lookupSessionStorage: 'valvuriLng',
    },
  });

i18n.on('languageChanged', (language: SupportedLanguages) => {
  Settings.defaultLocale = SUPPORTED_LANGUAGES.includes(language)
    ? language
    : FALLBACK_LANGUAGE;
});

if (process.env.NODE_ENV === 'development') {
  // Add changeLanguage method to window when testing, as calling it's a lot faster to change this way
  // instead of setting localStorage and reloading page
  // @ts-expect-error TS doesn't like setting random functions to window...
  window.changeLanguage = i18n.changeLanguage;
}

export default i18n;
