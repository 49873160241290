import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { lightTheme } from './light';

let theme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    mode: 'dark',
    background: {
      default: '#111',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
