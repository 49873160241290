import type { UserCredential } from 'firebase/auth';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import auth, { loginWithEmail, logout } from '../firebase/auth';
import i18n from '../translation/i18n';
import {
  FALLBACK_LANGUAGE,
  SupportedLanguages,
} from '../translation/i18n.interfaces';

// type UserRole = 'admin' | 'owner' | 'worker';
type Theme = 'light' | 'dark';

interface IUserStore {
  // Variables
  authLoaded: boolean;
  uid: string | null;
  email: string;
  theme: Theme;
  language: SupportedLanguages;
  // Computed
  ready: boolean;
  loggedIn: boolean;
  // Methods
  login: (password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  setEmail: (email: string) => void;
  setTheme: (theme: Theme) => void;
  setLanguage: (language: SupportedLanguages) => void;
}

class UserStore implements IUserStore {
  authLoaded = false;
  uid: string | null = null;
  email: string = '';
  theme: Theme = 'light';
  language: SupportedLanguages = FALLBACK_LANGUAGE;

  constructor() {
    makeObservable(this, {
      authLoaded: observable,
      uid: observable,
      email: observable,
      theme: observable,
      language: observable,
      ready: computed,
      loggedIn: computed,
    });

    makePersistable(this, {
      name: 'valvuriUser',
      storage: window.localStorage,
      properties: ['email', 'theme', 'language'],
    });

    auth.onAuthStateChanged((user) => {
      if (user) {
        runInAction(() => {
          this.authLoaded = true;
          this.uid = user.uid;
          this.email = user.email || '';
        });
      } else {
        runInAction(() => {
          this.authLoaded = true;
          this.uid = null;
        });
      }
    });
  }

  get ready() {
    return isHydrated(this) && this.authLoaded;
  }

  get loggedIn() {
    return this.ready && this.uid !== null;
  }

  login = async (password: string) => {
    return loginWithEmail(userStore.email, password).then((userCredential) => {
      runInAction(() => {
        this.uid = userCredential.user.uid;
        this.email = userCredential.user.email as string;
      });
      return userCredential;
    });
  };

  logout = async () => {
    console.log('logging out!');
    return logout();
  };

  setEmail = (email: string) => {
    runInAction(() => {
      this.email = email;
    });
  };

  setTheme = (theme: Theme) => {
    runInAction(() => {
      this.theme = theme;
    });
  };

  setLanguage = (language: SupportedLanguages) => {
    runInAction(() => {
      this.language = language;
      i18n.changeLanguage(language);
    });
  };
}

const userStore = new UserStore();
export default userStore;
