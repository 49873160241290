import {
  CircularProgress,
  Stack,
  StackProps,
  SxProps,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface ILoadingSpinner {
  text?: string;
  stackSx?: SxProps;
  stackProps?: StackProps;
}

const LoadingSpinner = observer((props: ILoadingSpinner) => {
  const { t } = useTranslation();
  const { text, stackSx, stackProps } = props;

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        width: '100%',
        paddingTop: '10%',
        ...stackSx,
      }}
      {...stackProps}
    >
      <CircularProgress />
      <Typography>{text ?? t('Loading...')}</Typography>
    </Stack>
  );
});

export default LoadingSpinner;
