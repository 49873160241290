import {
  connectAuthEmulator,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import config from '../config/getConfig';
import app from './app';

export const auth = getAuth(app);

if (config.useEmulators === true) {
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export const loginWithEmail = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const logout = async () => {
  return signOut(auth);
};

export default auth;
