import { CssBaseline, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense } from 'react';
import { Route, Router } from 'wouter';
import LoadingSpinner from './components/LoadingSpinner';
import userStore from './stores/User';
import darkTheme from './themes/dark';
import lightTheme from './themes/light';

const Login = lazy(() => import('./pages/Login'));
const Frontpage = lazy(() => import('./pages/Frontpage'));

// interface IPrivateRoute extends RouteProps {
//   allowUnverified?: boolean;
// }

// const PrivateRoute = observer((props: IPrivateRoute) => {
//   if (!userStore.loggedIn) {
//     return (
//       <Route path={props.path}>
//         <Redirect to="/login" />
//       </Route>
//     );
//   }
//   // if (props.allowUnverified !== true) {
//   //   return <Route {...props} component={Frontpage} />;
//   // }
//   return <Route {...props} />;
// });

const App = observer(() => {
  const theme = userStore.theme === 'dark' ? darkTheme : lightTheme;

  if (!userStore.ready) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <Route path="/login" component={Login} />
        </Suspense>
        <Suspense fallback={<LoadingSpinner />}>
          <Route component={userStore.loggedIn ? Frontpage : Login} />
        </Suspense>
      </Router>
    </ThemeProvider>
  );
});

export default App;
